<template>
	<f-card>
		<v-data-table :headers="headers" :items="demoIntervals" @click:row="edit" />
	</f-card>
</template>

<script>
export default {
	data() {
		return {
			headers: [
				{
					text: this.$t("labels.start"),
					align: "start",
					sortable: true,
					value: "begin",
				},
				{
					text: this.$t("labels.end"),
					align: "start",
					sortable: true,
					value: "end",
				},
			],
		};
	},
	computed: {
		demoIntervals() {
			return this.$store.state.demoIntervals.list;
		},
		loading() {
			return !this.$store.state.system.loading.done;
		},
	},
	methods: {
		edit(demoInterval) {
			this.$router.push({
				name: "settings-demo-intervals-edit",
				params: {
					id: demoInterval.id,
				},
			});
		},
	},
};
</script>
